import * as React from "react";
import { navigate } from 'gatsby';
import Navbar from '../components/Navbar';
import { getcolor, getColorScheme } from "../functions/helper";
import { Redirect } from '@reach/router';
import NumericInput from 'react-numeric-input';
import Disclaimer from "../components/Disclaimer";

// markup
const IndexPage = () => {
  const windowGlobal = typeof window !== 'undefined' && window;
  const firstVisit = windowGlobal.localStorage?.getItem('language') === null || windowGlobal.localStorage?.getItem('substance') === null || windowGlobal.localStorage?.getItem('mode') === null;

  const [colorScheme, setColorScheme] = React.useState(windowGlobal.localStorage?.getItem('colorScheme') ?? 'light');
  const [language, setLanguage] = React.useState(windowGlobal.localStorage?.getItem('language') ?? 'EN');
  const [substance, setSubstance] = React.useState(windowGlobal.localStorage?.getItem('substance') ?? 'GBL');
  const [mode, setMode] = React.useState(windowGlobal.localStorage?.getItem('mode') ?? 'simple');
  const [standardDosage, setStandardDosage] = React.useState(windowGlobal.localStorage?.getItem('standardDosage') ?? 1.2);
  const [message, setMessage] = React.useState(windowGlobal.localStorage?.getItem('message') ?? '')

  const color = getColorScheme(colorScheme);

  if (firstVisit) {
    return (<Redirect noThrow to="/setup" />);
  }

  const saveSettings = () => {
    windowGlobal.localStorage?.setItem('language', language);
    windowGlobal.localStorage?.setItem('substance', substance);
    windowGlobal.localStorage?.setItem('mode', mode);
    windowGlobal.localStorage?.setItem('colorScheme', colorScheme);
    windowGlobal.localStorage?.setItem('message', message);
    navigate('/');
  };

  const handleChange = () => {

  };

  return (
    <main
      style={{ color: color.textColor, backgroundColor: color.backgroundColor }}
    >
      <Navbar />
      <div className="max-w-screen-sm mx-auto px-4">
        <div
          className="my-4 sm:my-10 p-6 sm:p-10"
          style={{ backgroundColor: color.containerBackgroundColor }}
        >
          <h1 className="italic text-center">It's G Time!</h1>

          <p className="text-center mb-4">
            Edit your settings.
          </p>

          <h2>Language</h2>
          <div className="grid grid-cols-3 gap-x-4 mb-10">
            <div
              className={`option ${language === 'DA' ? 'selected' : ''}`}
              onClick={() => setLanguage('DA')}
              style={language !== 'DA' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <h3>
                <span className="block sm:hidden">
                  🇩🇰
                </span>

                <span className="hidden sm:block">
                  🇩🇰 Dansk
                </span>
              </h3>
            </div>

            <div
              className={`option ${language === 'EN' ? 'selected' : ''}`}
              onClick={() => setLanguage('EN')}
              style={language !== 'EN' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <h3>
                <span className="block sm:hidden">
                  🇬🇧
                </span>

                <span className="hidden sm:block">
                  🇬🇧 English
                </span>
              </h3>
            </div>

            <div
              className={`option ${language === 'DE' ? 'selected' : ''}`}
              onClick={() => setLanguage('DE')}
              style={language !== 'DE' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <h3>
                <span className="block sm:hidden">
                  🇩🇪
                </span>

                <span className="hidden sm:block">
                  🇩🇪 Deutsch
                </span>
              </h3>
            </div>
          </div>

          <h2>Substance</h2>
          <div className="grid grid-cols-3 gap-x-4 mb-10">
            <div
              className={`option ${substance === 'GHB' ? 'selected' : ''}`}
              onClick={() => setSubstance('GHB')}
              style={substance !== 'GHB' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <h3>GHB</h3>
            </div>

            <div
              className={`option ${substance === 'GBL' ? 'selected' : ''}`}
              onClick={() => setSubstance('GBL')}
              style={substance !== 'GBL' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <h3>GBL</h3>
            </div>

            <div
              className={`option ${substance === 'BDO' ? 'selected' : ''}`}
              onClick={() => setSubstance('BDO')}
              style={substance !== 'BDO' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <h3>BDO</h3>
            </div>
          </div>

          <h2>Mode</h2>
          <div className="grid grid-cols-2 gap-x-4 mb-10">
            <div
              className={`flex items-center option justify-center ${mode == 'simple' ? 'selected' : ''}`}
              onClick={() => setMode('simple')}
              style={mode !== 'simple' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <div>
                <div className="text-center text-xl font-semibold">
                  Simple
                </div>

                <div className="hidden sm:block">
                  You'll be presented with a simple interface, to track your dosages and times, and nothing else.
                </div>
              </div>
            </div>

            <div
              className={`flex items-center justify-center option ${mode == 'advanced' ? 'selected' : ''}`}
              onClick={() => setMode('advanced')}
              style={mode !== 'advanced' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              <div>
                <div className="text-center text-xl font-semibold">
                  Advanced
                </div>

                <div className="hidden sm:block">
                  Along with default functionality, you'll also be able to see statistics, edit your inputs and edit additional settings.
                </div>
              </div>
            </div>

            <div className="block sm:hidden mt-4 col-span-2">
              {
                mode == 'simple' ? `You'll be presented with a simple interface, to track your dosages and times, and nothing else.`
                  : mode == 'advanced' ? `Along with default functionality, you'll also be able to see statistics and edit your inputs.`
                    : 'Unknown mode ?_?'
              }
            </div>
          </div>

          <h2>Standard dosage</h2>
          <div className="mb-10">
            <div className="w-full">
              <NumericInput
                id="dosage"
                onChange={handleChange}
                className="w-full text-black"
                style={{ input: { height: '2rem', width: '100%' } }}
                step={0.1}
                precision={2}
                value={standardDosage}
                mobile={true}
                format={(num) => num + 'mL'}
              />
            </div>
          </div>

          <h2>Color Scheme</h2>
          <div className="mb-10 grid grid-cols-2 gap-x-4">
            <div
              className={`flex items-center option justify-center ${color == 'light' ? 'selected' : ''}`}
              onClick={() => setColorScheme('light')}
              style={colorScheme !== 'light' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              Light
            </div>

            <div
              className={`flex items-center option justify-center ${color == 'dark' ? 'selected' : ''}`}
              onClick={() => setColorScheme('dark')}
              style={colorScheme !== 'dark' ? { backgroundColor: color.buttonBackgroundColor } : { backgroundColor: color.selectedBackgroundColor }}
            >
              Dark
            </div>
          </div>

          {
            mode === 'advanced' && (
              <>
                <h2 className="mb-0">Message for yourself</h2>
                <div className="mb-10">
                  <div className="italic mb-4">
                    Get too high and out of control? Leave a message for yourself in the app, to remind <b>high you</b> to behave.
                  </div>
                  <textarea
                    className="w-full p-2 text-black"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  ></textarea>
                </div>
              </>
            )
          }

          <button
            className="big"
            onClick={saveSettings}
            style={{ backgroundColor: color.buttonBackgroundColor }}
          >
            Save
          </button>
        </div>
      </div>

      <Disclaimer />
    </main>
  )
}

export default IndexPage
